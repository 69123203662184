<template>
  <CRow>
    <CCol>
      {{ username }}
      <a href="javascript:" @click="showProfile(profilerId)">
        <i class="fas fa-eye"></i>
      </a>
    </CCol>

    <CModal
        :title="heading"
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      <CCardBody>

        <!--Start -->
        <CRow v-if="self.user.role==='User'">
          <CCol md="3">
            <CRow>
              <CCol md="9" class="text-center">
                <img alt="User Profile Picture" :src="avatar" class="rounded-circle" height="auto" width="100%"/>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="9" class="align-self-center">
            <CRow>

              <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.profile.user.name"
                       :value="user.name"></MakeCol>

              <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.profile.user.title"
                       :value="user.title"></MakeCol>

              <MakeCol v-show="user.email" :lg="4" :md="4" :sm="6" :title="$lang.profile.user.email"
                       :value="user.email"></MakeCol>

              <MakeCol v-show="user.status" :lg="4" :md="4" :sm="6" :title="$lang.profile.user.status"
                       :value="user.status"></MakeCol>

              <MakeCol v-show="user.country" :lg="4" :md="4" :sm="6" :title="lang.profile.user.country"
                       :value="user.country"></MakeCol>

              <MakeCol v-show="user.state" :lg="4" :md="4" :sm="6" :title="lang.profile.user.state"
                       :value="user.state"></MakeCol>

              <MakeCol v-show="user.city" :lg="4" :md="4" :sm="6" :title="lang.profile.user.city"
                       :value="user.city"></MakeCol>

              <MakeCol v-show="user.address1" :lg="4" :md="4" :sm="6" :title="lang.profile.user.address1"
                       :value="user.address1"></MakeCol>

              <MakeCol v-show="user.role" :lg="4" :md="4" :sm="6" :title="lang.profile.user.role"
                       :value="user.role"></MakeCol>

            </CRow>
          </CCol>
        </CRow>
        <!--End-->
        <CRow v-if="self.user.role==='Admin'">
          <CCol md="3">
            <CRow>
              <CCol md="9" class="text-center">
                <img alt="Company Profile Picture" :src="profileImage" class="rounded-circle" height="auto"
                     width="100%"/>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="9" class="align-self-center">
            <CRow>
              <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.form.company"
                       :value="profile.company"></MakeCol>
              <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.form.companyType"
                       :value="profile.companyType"></MakeCol>

              <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.form.user"
                       :value="profile.user"></MakeCol>

              <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.form.title"
                       :value="profile.title"></MakeCol>

              <MakeCol v-if="profile.whose === 'Company'" :lg="6" :md="4" :sm="4" :title="$lang.profile.form.status"
                       :value="profile.status"></MakeCol>
            </CRow>
            <CRow>
              <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.form.industry"
                       :value="profile.industry"></MakeCol>
              <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.form.subIndustry"
                       :value="profile.subIndustry"></MakeCol>
            </CRow>
            <CRow>
              <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.form.website"
                       :value="profile.website"></MakeCol>
              <MakeCol v-if="profile.skills" :lg="6" :md="4" :sm="4" :title="$lang.profile.form.skills"
                       :value="profile.skills"></MakeCol>
            </CRow>
            <CRow>
              <MakeCol v-if="profile.country" :lg="6" :md="4" :sm="4" :title="$lang.profile.form.country"
                       :value="profile.country"></MakeCol>
              <MakeCol v-if="profile.state" :lg="6" :md="4" :sm="4" :title="$lang.profile.form.state"
                       :value="profile.state"></MakeCol>
              <MakeCol v-if="profile.city" :lg="6" :md="4" :sm="4" :title="$lang.profile.form.city"
                       :value="profile.city"></MakeCol>
            </CRow>
            <CRow>
              <MakeCol v-if="profile.address1" :lg="6" :md="4" :sm="4" :title="$lang.profile.form.address1"
                       :value="profile.address1"></MakeCol>
            </CRow>
            <CRow>
              <CCol sm="6" md="6" lg="12" v-show="profile.description">
                <p class="custome-labal"> <b>{{ $lang.profile.form.description }} </b>
                  : <span  v-html="profile.description"></span>
                </p>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <template hide-footer #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CCardBody>
    </CModal>
  </CRow>
</template>
<script>
import {profile, skills} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import MakeCol from "/src/components/MakeCol";

export default {
  name: "ProfilerDetail",
  mixins: [Mixin],
  components: {MakeCol},
  props: ["profilerId", "title", "icon", 'username', 'type'],
  data() {
    return {
      heading: "",
      largeModal: false,
      self: this,
      module: profile,
      moduleSkills: skills,
      profileImage: "",
      avatar: "",
      profile: {
        name: "",
        title: "",
        company: "",
        subIndustry: "",
        companyType: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        industry: "",
        user: "",
        status: "",
        skills: "",
        whose: "",
        description: "",
      },
      user: {
        image: "",
        name: "",
        title: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        status: "",
        email: "",
        countryCode: "",
        role: "",
      },
    };
  },
  mounted() {
  },
  methods: {
    showProfile(userId) {
      // eslint-disable-next-line no-undef
      axios
          .get(this.viewUrlApi(this.module, userId))
          .then((response) => {
            if (response.data.data) {
              let userResponseData = response.data.data;
              self.user.name = userResponseData.name;
              self.user.role = userResponseData.role;
              self.user.title = userResponseData.title;
              self.user.email = userResponseData.email;
              self.user.address1 = userResponseData.address1;
              self.user.state = userResponseData.stateName;
              self.user.city = userResponseData.cityName;
              self.user.country = userResponseData.countryName;
              self.avatar = userResponseData.avatar;
              self.profile.whose = response.data.data.whose;
              if (response.data.data.parentId > 0) {
                self.heading = "User Profile";
              } else {
                self.heading = "Company Profile";
              }
              const responseData = response.data.data.profile;
              let listSubIndustry = [];
              responseData.subIndustry.map(function (value) {
                listSubIndustry.push(value.label);
              });
              self.profile.subIndustry = listSubIndustry.join(", ");
              self.profile.name = responseData.name;
              self.profile.title = responseData.title;
              self.profile.company = responseData.company;
              self.profile.address1 = responseData.address1;
              self.profile.description = responseData.description;
              self.profile.industry = responseData.industryTitle;
              self.profile.status = responseData.industryTitle;
              self.profileImage = responseData.image;
              self.profile.state = responseData.stateName;
              self.profile.city = responseData.cityName;
              self.profile.status = responseData.profileStatus;
              self.profile.isVerified = responseData.isVerifiedId;
              self.profile.user = responseData.name;
              self.profile.companyType = responseData.companyTypeTitle;
              self.profile.country = responseData.countryName;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
          });

      let self = this;
      self.largeModal = true;
    },
  },
};
</script>
