<template>
  <CCol :sm="sm" :md="md" :lg="lg" v-show="value">

    <p v-if="link" class="custome-labal" style="font-weight:normal"><b>{{ title }}</b> :
      <a :href="link" target="_blank"> {{ value }} </a>
    </p>
    <p v-else-if="badge" class="custome-labal" style="font-weight:normal"><b>{{ title }}</b> :
      <CBadge :color="statusBudget(badge)">{{ value }}</CBadge>
    </p>
    <p v-else class="custome-labal" style="font-weight:normal"><b>{{ title }}</b> :
      {{value}}
    </p>
  </CCol>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "MakeCol",
  mixins: [Mixin],
  props: ["title", "value", "sm","md","lg","link","badge"],
  data() {
    return {
      self: this,
    };
  },
};
</script>
