<template>
  <div class="row">
    <!--     <CCol sm="6" md="4" lg="4" v-show="createdBy">-->
    <!--       <p class="custome-labal">{{ $lang.common.form.created_by }}</p>-->
    <!--       <Avatar-->
    <!--           :image="avatar"-->
    <!--           :defaultImage="avatar"-->
    <!--           :content="createdBy"-->
    <!--           :allowCompany="true"-->
    <!--           :length="20"-->
    <!--       />-->
    <!--     </CCol>-->

    <CCol sm="6" md="4" lg="4" v-show="profilerId">
      <p class="custome-labal">{{ $lang.common.form.created_by }}</p>
      <profiler-detail :profilerId="profilerId"></profiler-detail>
    </CCol>
    <CCol sm="6" md="4" lg="4" v-show="createdAt">
      <p class="custome-labal">{{ $lang.common.form.created_at }}</p>
      <p class="custome-detail">{{ createdAt }}</p>
    </CCol>
    <CCol sm="6" md="4" lg="4" v-show="updatedAt">
      <p class="custome-labal">{{ $lang.common.form.updated_at }}</p>
      <p class="custome-detail">{{ updatedAt }}</p>
    </CCol>

  </div>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";
import ProfilerDetail from "/src/views/ProfilerDetail";

export default {
  name: "EntityTimeStamp",
  mixins: [Mixin],
  components: {ProfilerDetail},
  props: ["createdAt", "updatedAt", 'createdBy', 'avatar', 'profilerId'],
  data() {
    return {
      self: this,
    };
  },
};
</script>
